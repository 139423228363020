

















































import useAccessory from "@/use/accessory";
import useApi from "@/use/api";
import useUser from "@/use/user";
import {
  defineComponent,
  nextTick,
  reactive,
  ref,
  watch,
} from "@vue/composition-api";

export default defineComponent({
  components: {
    MCompanyFilter: () => import('@/components/molecules/m-company-filter.vue'),
    MUserInput: () => import('@/components/molecules/m-user-input.vue')
  },
  props: {
    isOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(_, { root, emit }) {
    const { hasAccessTo, hasManyCompanies } = useUser({ root })

    const { getAccessoryIcon } = useAccessory()

    const form = ref()

    const state = reactive({
      loading: false,
      company: null,
      isValid: false,
      error: null as null | string
    });

    const model = reactive({
      company: null as any,

      type: '',
      name: '',
      serial: '',

      assign: false,
      user: null as any
    });

    const types = [
      { value: 'phone', text: 'Smartfon' },
      { value: 'laptop', text: 'Laptop' },
      { value: 'other', text: 'Inne' },
    ]

    const reset = () => {
      model.type = '';
      model.name = '';
      model.serial = '';
    };

    watch(
      () => state.company,
      () => reset()
    );

    const close = () => {
      emit("update:isOpen", false);
      nextTick(reset);
    };

    const save = async () => {
      state.error = null
      await form.value?.validate()

      if (state.isValid) {
        state.loading = true

        const { axiosInstance } = useApi({ root })

        await axiosInstance
          .post('inventory/accessory', {
            company: model.company ? model.company.id : undefined,

            type: model.type || undefined,
            name: model.name || undefined,
            serial: model.serial || undefined,
          })
          .then(({ data: { accessory }}) => {
            if (model.assign && model.user) {
              emit('action', { type: 'accessoryHandover', user: model.user, accessory, step: 4 })
            }

            emit('refresh')
            nextTick(close);
          })
          .catch(() => {
            state.error = 'Nie udało się dodać akcesorium'
          })
          .finally(() => state.loading = false)
      } else {
        setTimeout(() => state.loading = false, 1000)
      }
    };

    return {
      hasAccessTo,
      hasManyCompanies,
      getAccessoryIcon,

      form,

      state,
      model,

      types,

      close,
      save,
    };
  },
});
